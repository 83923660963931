
import {Component, Vue} from 'vue-property-decorator';
import download from '@/infrastructure/api/Download';
import pdf from 'vue-pdf';

@Component({
  components: {
    pdf,
  },
})
export default class Home extends Vue {
  // data
  fixDataUrl: string = '';
  fileName = '受講修了書.pdf';
  webkitBlobResourceBugHeight = 842;
  webkitBlobResourceBugWidth = 595;
  webkitBlobResourceBrowser = false;
  webkitBlobResourceMessage = '申し訳ございません。\nご利用のOS,ブラウザではこのボタンからダウンロードすることができません。\nアドレスバー右のアイコンより印刷、またはPDF保存をお試しくださいませ。';

  get height(): number {
    if (download.isIOs()) {
      return this.webkitBlobResourceBugHeight;
    }
    return window.innerHeight;
  }
  get width(): number {
    if (download.isIOs()) {
      return this.webkitBlobResourceBugWidth;
    }
    return window.innerWidth;
  }
  // method
  async showPDF(): Promise<void> {
    const blob = await  download.getPDFData();
    this.fixDataUrl = await download.getPDFDataUrl(blob);
    await download.downloadPdf(blob, this.fileName);
  }
  async downloadPdf(): Promise<void> {
    if (this.webkitBlobResourceBrowser) {
      alert(this.webkitBlobResourceMessage);
      return;
    }
    const blob = await  download.getPDFData();
    await download.downloadPdf(blob, this.fileName);
  }
  // lifecycle hooks
  created(): void {
    this.showPDF();
    if (download.isSafari() && download.hasWebkitBlobResourceBug()) {
      this.webkitBlobResourceBrowser = true;
    }
  }
}
